import React from "react";
import {List, ListItem, styled, Typography} from "@mui/material";
import {THEME_CONFIG} from "../../../config";

const {poll} = THEME_CONFIG

export default function QuizQuestion({risposte, corretta, answer, sendAnswer, isPoll}) {
    const RispostaStyled = styled(ListItem)({
        border: `2px solid ${poll.default}`,
        borderRadius: '0.7rem',
        background: poll.default,
        margin: '1rem 0',
        cursor: 'pointer',
        minHeight: '90px'
    })

    const computeBkg = (r) => {
        if(!isPoll) {
            if (answer !== '') {
                if (corretta === r) {
                    return poll.correct
                } else if (answer === r  && answer !== corretta){
                    return poll.wrong
                }
            }
        } else {
            if(answer === r){
                return poll.answered
            }
        }
    }

    const computeColor = (r) => {
        if(answer === r) {
            return true
        }

        return false
    }

    const Risposte = () => (
        risposte.map((r, i) => (
            <RispostaStyled key={i} style={{
                width: '100%',
                background: computeBkg(r),
                border: computeBkg(r) ? '2px solid #e9cc00' : `2px solid #e9cc00`,
                borderRadius: '0.4rem',
                color: computeColor(r) ? '#2b2672' : 'white',
                textAlign:'center'
            }} onClick={() => rispondi(r)}>
                <Typography variant={"body1"} fontWeight='bold' sx={{textAlign:'center', width:'100%'}}>
                    {r.toUpperCase()}
                </Typography>
            </RispostaStyled>
        ))
    )

    const rispondi = (r) => {
        if(isPoll) {
            sendAnswer(r)
        } else {
            if(answer === ''){

                sendAnswer(r)
            }
        }
    }

    return (
        <List>
            <Risposte/>
        </List>
    )
}
