import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogContent, IconButton, Stack, Typography} from "@mui/material";
import questionType from "../assets/data/typeMap";
import {QuestionSwitch} from "./Questions/QuestionSwitch";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {ArrowBack, ArrowBackIosNew, Close} from "@mui/icons-material";
import viatrisLogo from "../assets/evento/logo.svg";

export const PinnedQuestions = ({questions}) => {
    const [selected, setSelected] = useState('')
    const {id} = useParams()

    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(() => {
        selected !== '' ? getElementPlaying() : setElementPlaying({})
    }, [selected]);

    function getElementPlaying() {
        firestore.collection('users/plesh/events/'+id+'/queue').doc(selected).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }

    return (
        <Box>
            {selected !== '' && <Dialog fullScreen={true} open={selected !== ''} onClose={() => setSelected('')} maxWidth={'md'} fullWidth={true}>
                <DialogContent>
                    <Stack mt={3}>
                        <img style={{
                            width: '120px',
                            margin:'auto',
                        }} src={viatrisLogo}/>
                        <Stack mt={'20%'} direction={'row'} alignItems={'center'} justifyContent={'center'}>
                            <Box position={'absolute'} top={'10%'} left={30}>
                                <IconButton onClick={() => setSelected('')}>
                                    <ArrowBackIosNew sx={{fontSize: '30px'}}/>
                                </IconButton>
                            </Box>
                            <Typography textAlign={'center'} variant={'h3'} fontWeight={'bold'}>
                                {elementPlaying.domanda}
                            </Typography>
                        </Stack>
                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center',height: '70%'}}>
                            <QuestionSwitch elementPlaying={elementPlaying}/>
                        </Box>
                    </Stack>

                </DialogContent>
            </Dialog>}
            <Stack spacing={4} alignItems={'center'}>
                {
                    questions.docs.sort((a, b) => a.data().position - b.data().position).map((q, i) => {
                        console.log("q:",q.data())
                            return <Button key={i} variant={'home-question'} /*startIcon={questionType[q.data().tipo].icon}*/
                                    onClick={() => setSelected(q.id)}>
                                {q.data().conferenza === "hxKl7qntZ700E5ea5LX5" ? "Q&A - NEPSY"
                                    : q.data().conferenza === 'jRIATYkPcIqcsRABrtmQ' ? "Q&A - CARDIO"
                                        : q.data().domanda}
                            </Button>
                        }
                    )
                }
            </Stack>
        </Box>
    )
}