import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import pleshDark from "./theme/pleshDark";
import Vote from "./pages/Vote";
import React from "react";
import {CssBaseline, ThemeProvider} from "@mui/material";
import bkgTop from "./assets/evento/bkg-top.png"
import bkgBottom from "./assets/evento/bkg-bottom.png"

function App() {
    return (
        <ThemeProvider theme={pleshDark}>
            <CssBaseline/>
            <div className="App">
                <img src={bkgTop} width={'100%'} style={{position: 'absolute', top: 0, left: 0, right: 0,}}/>
                <img src={bkgBottom} width={'100%'} style={{position: 'absolute', bottom: 0, left: 0, right: 0,}}/>
                <Routes>
                    <Route path='/' element={<Navigate to={'/vote/yNVQI5B0infQLYycIMvf'}/>}/>
                    <Route path='/vote/:id' element={<Vote/>}/>
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;