import React, {useState} from "react";
import {Autocomplete, Box, Button, FormControl, MenuItem, Stack, TextField, Typography} from "@mui/material";
import wordCheck from "../../../functions/wordCheck";
import SuccessCheck from "../../shared/SuccessCheck";
import {motion, useMotionValue} from "framer-motion";

export default function WordcloudQuestion({risposte, sendAnswer, success, answered}) {
    const [word, setWord] = useState('')
    const [spazio, setSpazio] = useState(false)

    let progress = useMotionValue(90)

    const handleSetWord = (event) => {
        setWord(event.target.value)
    }

    function capitalizeTheFirstLetterOfEachWord(words) {
        var separateWord = words.toLowerCase().split(' ');
        for (var i = 0; i < separateWord.length; i++) {
            separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
                separateWord[i].substring(1);
        }
        return separateWord.join(' ');
    }

    const handleSendAnswer = () => {
        let w = capitalizeTheFirstLetterOfEachWord(word).trim()

        setSpazio(false)
        if (w.includes(' ')) {
            setSpazio(true)
        } else {
            if (!risposte) {
                if (wordCheck(w)) {
                    sendAnswer(w)
                    setWord('')
                } else {
                    setWord('')
                }
            } else {
                sendAnswer(w)
                setWord('')
            }
        }

    }

    return (
        <Stack sx={{position: 'relative'}} justifyContent={"center"} alignItems={"center"}
               style={{textAlign: 'center', height: '100%'}} spacing={5}>
            {(success) &&
                <Stack style={{width: '200px'}} paddingX={8}>
                    <motion.div
                        initial={{x: 0}}
                        animate={{x: 100}}
                        style={{x: progress}}
                        transition={{duration: 0.8}}
                    />
                    <SuccessCheck progress={progress}/>
                </Stack>
            }
            {!risposte ?
                <Box sx={{width: '100%', mt: 5}}>
                    <FormControl variant="standard" style={{width: '100%'}}>
                        <TextField
                            inputProps={{style: {fontSize: '1.4rem', color: 'white'}}}
                            placeholder="Inserisci una parola" id="component-simple" value={word}
                            sx={{fontSize: '3rem'}} onChange={handleSetWord}/>
                    </FormControl>
                    {!risposte && word && word.includes(' ') &&
                        <Typography sx={{mt: 1}}>Puoi inviare solo una parola</Typography>
                    }
                    <Button disabled={word.length < 2 || word.includes(' ')} sx={{mt: 4}} variant={'default'}
                            onClick={handleSendAnswer}>
                        {success ? "Send another" : "Send"}
                    </Button>
                </Box>
                :
                <Box sx={{width: '100%', mt: 5}}>
                    <FormControl fullWidth variant={'standard'}>
                        <Autocomplete
                            renderInput={(params) =>
                                <TextField {...params}
                                           label="Scegli un farmaco"/>}
                            options={risposte}
                            value={word}
                            onChange={(event, newValue) => {
                                setWord(newValue);
                            }}
                        />
                    </FormControl>
                    <Button disabled={!word || word.length < 2} sx={{mt: 4}} variant={'default'}
                            onClick={handleSendAnswer}>
                        {success ? "Send another" : "Send"}
                    </Button>
                </Box>
            }
        </Stack>
    )
}
