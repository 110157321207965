import React, {useEffect, useState} from "react";
import {firestore} from "../firebase/clientApp";
import {Avatar, Box, Button, ButtonGroup, Container, Grid, Typography} from "@mui/material";

import axios from "axios";
import {useParams} from "react-router-dom";
import {useDocument} from "react-firebase-hooks/firestore";

import clap from '../assets/images/clap.png'
import {emojisplosion} from "emojisplosion";
import {API_URL} from "../config";


export default function Applausometro({url, idPlaying, eventPlaying, user}) {
    const {id} = useParams()

    async function postDataApplausi(e) {
        emojisplosion({
            emojiCount:5,
            emojis:['⚡️',' 🔥','💥','⭐️','🦾'],
            position: () => ({
                x: e.clientX,
                y: e.clientY,
            }),
        })
        await axios.post(`${API_URL}/${id}/applausi`, {user: user.uid})
            .then(r => {
                navigator.vibrate(200)
            })
            .catch(error => console.log(error))
    }

    const ButtonItem = () => (
        <Button id='clapButton' disabled={!eventPlaying} onClick={postDataApplausi} fullWidth
                variant={"clap"}
            startIcon={<img style={{height:'3.625rem'}} src={clap} />}
        >
        </Button>
    )

    return (
        <Box sx={{position:'absolute', bottom:'0', my:2, width:'min(35rem, calc(100% - 32px))'}}>
            <ButtonItem/>
        </Box>
    )
}
